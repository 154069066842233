/* import __COLOCATED_TEMPLATE__ from './library-summary.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import {
  aiContentEntities,
  AI_CONTENT_ENTITIES_NAMES,
} from 'embercom/lib/ai-content-library/constants';
import { humanReadableObjectNames } from 'embercom/models/data/matching-system/matching-constants';
import { capitalize } from '@ember/string';
import type ContentImportSource from 'embercom/models/content-service/content-import-source';
import { EntityType } from 'embercom/models/data/entity-types';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

interface Args {
  externalContentSources: ContentImportSource[];
  contentLibrarySummary: {
    [key: number]: {
      entity_type: number;
      total_count: number;
      used_by_fin_count: number;
      additional_data: any;
    };
  };
  fetchLibrarySummary: () => TaskGenerator<void>;
}

export default class LibrarySummary extends Component<Args> {
  @service declare appService: any;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    taskFor(args.fetchLibrarySummary).perform();
  }

  get isIdle(): boolean {
    return taskFor(this.args.fetchLibrarySummary).isIdle;
  }

  get librarySummary() {
    let librarySummary: any = [];
    if (Object.keys(this.args.contentLibrarySummary).length !== 0) {
      aiContentEntities().forEach((type) => {
        if (!this.args.contentLibrarySummary[type]) {
          return;
        }

        librarySummary.pushObject({
          entityName: capitalize(humanReadableObjectNames[type]),
          entityType: this.args.contentLibrarySummary[type].entity_type,
          totalCount: this.args.contentLibrarySummary[type].total_count,
          usedByFinCount: this.args.contentLibrarySummary[type].used_by_fin_count,
          additionalData: this.args.contentLibrarySummary[type].additional_data,
        });
      });
    }

    return librarySummary;
  }

  get isExternalContent() {
    return EntityType.ExternalContent;
  }

  get humanReadableEntityNames() {
    return AI_CONTENT_ENTITIES_NAMES;
  }

  get latestSourceInProgress(): ContentImportSource | undefined {
    return this.args.externalContentSources.find((source) => source.latestImportRun?.isInProgress);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::LibrarySummary': typeof LibrarySummary;
  }
}
