/* import __COLOCATED_TEMPLATE__ from './language-selector.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import {
  FIN_DEFAULT_LOCALE,
  FIN_SUPPORTED_LANGUAGES,
  type Language,
} from 'embercom/lib/ai-content-library/constants';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    currentLocale: string | undefined;
    setLocale: (locale?: string) => void;
    knowledgeHubEditor?: boolean;
    isDisabled?: boolean;
  };
}

interface DropDownOption {
  text: string;
  value: string;
}

export default class LanguageSelector extends Component<Signature> {
  @service declare appService: any;
  @service declare intl: IntlService & { languageNameFromCode: (code: string) => string };

  get languageOptions(): Array<DropDownOption> {
    let supportedLanguages = FIN_SUPPORTED_LANGUAGES.map((language: Language) => {
      return {
        text: language.name,
        value: language.locale,
      };
    });

    let currentLocale = this.args.currentLocale || FIN_DEFAULT_LOCALE;
    let languageFromLocale = this.intl.languageNameFromCode(currentLocale);
    let language =
      languageFromLocale.length === 0
        ? this.intl.t('ai-content-library.language-selector.unsupported-language-code', {
            unsupportedLoc: currentLocale,
          })
        : languageFromLocale;

    if (!FIN_SUPPORTED_LANGUAGES.some((language: Language) => language.locale === currentLocale)) {
      supportedLanguages.push({
        text: language,
        value: currentLocale,
      });
    }

    return supportedLanguages;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::LanguageSelector': typeof LanguageSelector;
  }
}
