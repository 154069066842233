/* import __COLOCATED_TEMPLATE__ from './render-snippet-body.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    serializedJsonBlocks: any | undefined;
    jsonBlocksHasContent: boolean;
    description?: string;
    styleForKnowledge?: boolean;
  };
}

const RenderSnippetBody = templateOnlyComponent<Signature>();
export default RenderSnippetBody;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::View::RenderSnippetBody': typeof RenderSnippetBody;
  }
}
