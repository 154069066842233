/* import __COLOCATED_TEMPLATE__ from './content-preview-cell.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import type ContentWrapper from 'embercom/models/content-service/content-wrapper';

interface Args {
  contentWrapper: ContentWrapper;
}

export default class ContentPreviewCell extends Component<Args> {
  get previewText(): string | undefined {
    return this.args.contentWrapper.contents.firstObject!.contentData.content;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::ContentReviews::ContentPreviewCell': typeof ContentPreviewCell;
  }
}
