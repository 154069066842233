/* import __COLOCATED_TEMPLATE__ from './created-at-cell.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import type ContentWrapper from 'embercom/models/content-service/content-wrapper';

interface Args {
  contentWrapper: ContentWrapper;
}

export default class CreatedAtCell extends Component<Args> {
  get createdAt(): Date {
    return this.args.contentWrapper.contents.firstObject!.createdAt;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::List::Cells::CreatedAtCell': typeof CreatedAtCell;
  }
}
