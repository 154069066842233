/* import __COLOCATED_TEMPLATE__ from './workflow-language-option.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface LanguageItem {
  text: string;
  isLoading: boolean;
  isSelected: boolean;
}

interface Signature {
  Args: {
    item: LanguageItem;
  };
}

export default class WorkflowLanguageOption extends Component<Signature> {
  @tracked isHovered = false;

  @action
  handleMouseEnter() {
    this.isHovered = true;
  }

  @action
  handleMouseLeave() {
    this.isHovered = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentEditor::Headers::ViewMode::WorkflowLanguageOption': typeof WorkflowLanguageOption;
  }
}
