/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import type ContentSnippet from 'embercom/models/content-service/content-snippet';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { FinAvailability } from 'embercom/lib/ai-content-library/constants';
import { tracked } from '@glimmer/tracking';
import { Status } from 'embercom/models/data/content-service/content-review-statuses';
import type Store from '@ember-data/store';
import type ContentImportService from 'embercom/services/content-import-service';
import { isLocaleSupported } from 'embercom/components/content-service/ai-content-library/helpers';

interface EditorArgs {
  isEditing: boolean;
  isLoading?: boolean;
  contentSnippet: ContentSnippet;
  onCancel?: () => void; // there is no cancel button for create mode, only in edit mode
  onSave: () => void;
  styleForKnowledge?: boolean;
}

export default class Editor extends Component<EditorArgs> {
  @service declare notificationsService: any;
  @service declare intl: IntlService & { languageNameFromCode: (code: string) => string };
  @service declare appService: any;
  @service declare store: Store;
  @service declare intercomEventService: any;
  @service declare contentImportService: ContentImportService;
  @tracked showFirstExperienceBanner = true;

  constructor(owner: unknown, args: EditorArgs) {
    super(owner, args);
  }

  get toggleIsEnabled(): boolean {
    if (!this.args.contentSnippet) {
      return false;
    }

    return isLocaleSupported(this.args.contentSnippet.locale);
  }

  get disabledToggleExplanation(): string {
    return this.intl.t(
      'ai-content-library.content-snippet.side-drawer.toggle-disabled-explanation.unsupported-language',
    );
  }

  @action async saveContentSnippet() {
    try {
      await this.args.contentSnippet.save();
      this.args.onSave();
      let notifyConfirmation = this.getNotificationMessage();
      this.notificationsService.notifyConfirmation(this.intl.t(notifyConfirmation));
      this.contentImportService.showFinIngestionJobStatusBanner();
    } catch (e) {
      this.args.onSave();
      this.notificationsService.notifyResponseError(e, {
        default: this.intl.t('ai-content-library.content-snippet.side-drawer.create-error'),
      });
    }
    let object = this.args.isEditing ? 'edit_save_button' : 'create_save_button';
    this.trackAnalyticsEvent('clicked', object);
  }

  @action onCancel(): void {
    this.args.contentSnippet.rollbackAttributes();
    if (this.args.onCancel) {
      this.args.onCancel();
    }
    this.trackAnalyticsEvent('clicked', 'cancel_button');
  }

  @action async onDelete(): Promise<void> {
    let response = await this.args.contentSnippet.destroyRecord();
    if (response) {
      this.notificationsService.notifyConfirmation(
        this.intl.t('ai-content-library.content-snippet.side-drawer.delete-success'),
      );
    } else {
      this.notificationsService.notifyConfirmation(
        this.intl.t('ai-content-library.content-snippet.side-drawer.delete-error'),
      );
    }
    this.args.onSave();
    this.trackAnalyticsEvent('clicked', 'delete_button');
  }

  @action
  setTitle(newTitle: string): void {
    this.args.contentSnippet.newOrCurrentVersion.title = newTitle;
  }

  @action
  setLocale(newLocale: string): void {
    this.args.contentSnippet.locale = newLocale;
  }

  @action onToggleClick(): void {
    this.args.contentSnippet.toggleAllAvailabilities();
  }

  willDestroy(): void {
    // called when clicking outside of the drawer during editing
    super.willDestroy();
    this.args.contentSnippet?.rollbackAttributes();
  }

  @action toggleFirstExperienceBanner(): void {
    this.showFirstExperienceBanner = false;
    this.trackAnalyticsEvent('clicked', 'close_banner');
  }

  private getNotificationMessage(): string {
    let message;
    if (this.args.isEditing) {
      if (this.args.contentSnippet?.contentReviewStatus === Status.Pending) {
        message = 'ai-content-library.content-reviews.notifications.snippet-update';
      } else {
        message =
          this.args.contentSnippet?.chatbotAvailability === FinAvailability.ON
            ? 'ai-content-library.content-snippet.side-drawer.update-success-with-fin-warning'
            : 'ai-content-library.content-snippet.side-drawer.update-success';
      }
    } else if (this.args.contentSnippet?.contentReviewStatus === Status.Pending) {
      message = 'ai-content-library.content-reviews.notifications.snippet-creation';
    } else {
      message =
        this.args.contentSnippet?.chatbotAvailability === FinAvailability.ON
          ? 'ai-content-library.content-snippet.side-drawer.create-success-with-fin-warning'
          : 'ai-content-library.content-snippet.side-drawer.create-success';
    }
    return message;
  }

  private trackAnalyticsEvent(action: string, object: string, metadata?: any): void {
    let place = this.args.contentSnippet?.isPendingReview
      ? 'fin_content_suggestions'
      : 'fin_content_page';

    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      section: 'operator',
      context: 'snippet_sidedrawer',
      place,
      ...metadata,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::ContentSnippets::Editor': typeof Editor;
  }
}
