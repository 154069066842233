/* import __COLOCATED_TEMPLATE__ from './detailed-view.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class DetailedView extends Component {
  @service intl;
  @service router;

  get controlExecutions() {
    return this.args.executionsForCurrentJourney || {};
  }

  contentForStep(step) {
    if (step.isChatMessage || step.isAnswerBot) {
      return step.oneLineSummary;
    } else if (step.isWorkflowConnector) {
      return this.intl.t('operator.custom-bot.path-analysis.custom-action-succeeded');
    } else if (step.isAttributeCollector) {
      return step.controls.firstObject.buttonLabel;
    } else if (step.isFreeInput) {
      return this.intl.t('operator.custom-bot.path-analysis.customer-reply');
    } else if (step.isSnooze) {
      return this.intl.t('operator.custom-bot.path-analysis.snooze-finished');
    } else if (step.isWait) {
      return this.intl.t('operator.custom-bot.path-analysis.wait-finished');
    } else if (step.isTriggerWorkflow) {
      return this.intl.t('operator.custom-bot.path-analysis.passed-to-workflow', {
        htmlSafe: true,
        url: this.router.urlFor(
          'apps.app.operator.custom-bots.custom-bot.show',
          step.triggerableWorkflowCustomBotId,
          { queryParams: { view: 'pathAnalysis' } },
        ),
        workflow_name: step.triggerableWorkflowTitle,
      });
    }
  }

  hoverTextForStep(step, count) {
    if (step.isChatMessage || step.isAnswerBot || step.isWorkflowConnector) {
      return null;
    } else if (
      step.isFreeInput ||
      step.isAttributeCollector ||
      step.isReplyButtons ||
      step.isCustomObjectSelector ||
      step.isConditionalBranches ||
      step.isTriggerWorkflow
    ) {
      return this.intl.t('operator.custom-bot.path-analysis.step-stat', {
        executionCount: count,
        stepType: step.type,
      });
    } else if (step.isSnooze) {
      return this.intl.t('operator.custom-bot.path-analysis.snooze-stat', {
        executionCount: count,
      });
    } else if (step.isWait) {
      return this.intl.t('operator.custom-bot.path-analysis.wait-stat', {
        executionCount: count,
      });
    }
  }

  executionsForSingleControlStep(step) {
    if (step.isChatMessage || step.isAnswerBot) {
      return null;
    }

    return this.controlExecutions[step.midPathControls.firstObject.uuid] || 0;
  }

  get singleControlSteps() {
    let visibleSingleControlSteps = this.args.steps.filter(
      (step) =>
        ((step.hasControls && step.hasNoActions) || step.isAnswerBot) &&
        !step.isMidPathActions &&
        !step.isTerminal &&
        !step.canOnlyBranch &&
        !step.isSetExpectations,
    );
    return visibleSingleControlSteps.map((step) => {
      let executionCount = this.executionsForSingleControlStep(step);
      return {
        text: this.contentForStep(step),
        executions: executionCount,
        hoverText: this.hoverTextForStep(step, executionCount),
      };
    });
  }

  get singleControlStepsExist() {
    return this.singleControlSteps.length > 0;
  }

  get branchingControls() {
    let branchingSteps = this.args.steps.filter((s) => s.canBranch);
    let branchesPerStep = branchingSteps.map((s) =>
      s.branchingControls.map((branchingControl, i) => {
        let executionCount = this.controlExecutions[branchingControl.uuid] || 0;
        return {
          text: this._branchingControlText(s, branchingControl, i),
          executions: this._showExecutionCount(s) ? executionCount : null,
          hoverText: this.hoverTextForStep(s, executionCount),
        };
      }),
    );

    return [].concat(...branchesPerStep);
  }

  _branchingControlText(step, control, index) {
    if (step.isReplyButtons) {
      return control.buttonLabel;
    } else if (step.isConditionalBranches) {
      return index === step.branchingControls.length - 1
        ? this.intl.t('operator.custom-bot.path-analysis.conditions.else')
        : this.intl.t('operator.custom-bot.path-analysis.conditions.condition', {
            number: index + 1,
          });
    } else if (step.isCustomObjectSelector) {
      return control.isFollowUp
        ? step.selectedCustomObject.name
        : this.intl.t('operator.workflows.custom-object-selector.editor.none-above');
    } else if (step.isWorkflowConnector) {
      if (control.type === 'success') {
        return this.intl.t('operator.custom-bot.path-analysis.custom-action-succeeded');
      }
      return this.intl.t('operator.custom-bot.path-analysis.error.custom-action');
    } else if (step.isFin) {
      return this.intl.t('operator.custom-bot.path-analysis.fin-passed-to-team');
    } else {
      return this.contentForStep(step);
    }
  }

  _showExecutionCount(step) {
    return step.isEndStateStep || step.isWorkflowConnector;
  }
}
