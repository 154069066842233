/* import __COLOCATED_TEMPLATE__ from './mention-typeahead.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { type TypeaheadMatchResult } from '@intercom/embercom-prosemirror-composer';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';
import type Composer from '@intercom/embercom-prosemirror-composer/lib/composer';

interface Args {
  composer: Composer;
  matchResult: TypeaheadMatchResult | null;
  registerDelegate(f: Function): void;
  deregisterDelegate(f: Function): void;
}

export default class MentionTypeahead extends Component<Args> {
  @service declare appService: any;
  @service regionService: any;

  get app() {
    return this.appService.app;
  }

  @computed('app.humanAdmins', 'args.matchResult.text.length')
  get filteredAdmins(): Array<any> {
    if (!this.args.matchResult) {
      return [];
    }
    if (this.args.matchResult.text.length === 1) {
      return this.app.humanAdmins;
    }
    let matchWithoutTrigger = this.args.matchResult.text.replace('@', '');
    let escapedMatch = this.escapeRegex(matchWithoutTrigger);
    let regexp = new RegExp(`^${escapedMatch}`, 'i');
    return this.app.humanAdmins.filter((item: any) => regexp.test(item.name));
  }

  private escapeRegex(string: string) {
    return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
  }

  @action
  insertMention(selectedItem: any) {
    if (!this.args.matchResult) {
      return;
    }
    let prosemirrorState = this.args.composer.state.prosemirrorState;
    if (prosemirrorState) {
      let uri = `//${this.regionService.appHost}/apps/${
        this.appService.app.id
      }/admin/${selectedItem.get('id')}`;
      let mentionSpec = prosemirrorState.schema.nodes.mention;
      let tr = prosemirrorState.tr;
      let node = mentionSpec.createAndFill({ name: selectedItem.get('name'), uri });
      tr.delete(this.args.matchResult.range.from, this.args.matchResult.range.to);
      tr.insert(this.args.matchResult.range.from, node);
      this.args.composer.commands.dispatch(tr);
      this.args.composer.commands.insertText(' ');
      this.args.composer.commands.focus();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Conversation::Composer::MentionTypeahead': typeof MentionTypeahead;
    'conversation/composer/mention-typeahead': typeof MentionTypeahead;
  }
}
