/* import __COLOCATED_TEMPLATE__ from './header-new-workflows-btn.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';

interface Args {
  openCreateFromScratchModal: () => void;
  openCreateFromTemplateModal: () => void;
}

export default class HeaderNewWorkflowsBtn extends Component<Args> {
  @service declare intl: IntlService;

  get dropdownItems() {
    return [
      {
        items: [
          {
            icon: 'add',
            text: this.intl.t(
              'outbound.new-content-modal.workflows.template-selector.create-new-workflow-from-template',
            ),
            onSelectItem: this.args.openCreateFromTemplateModal,
          },
          {
            icon: 'post-filled',
            text: this.intl.t(
              'outbound.new-content-modal.workflows.template-selector.create-new-workflow',
            ),
            onSelectItem: this.args.openCreateFromScratchModal,
          },
        ],
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CustomBots::HeaderNewWorkflowsBtn': typeof HeaderNewWorkflowsBtn;
  }
}
