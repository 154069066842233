/* import __COLOCATED_TEMPLATE__ from './content-type-cell.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import Component from '@glimmer/component';
import type ContentWrapper from 'embercom/models/content-service/content-wrapper';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import {
  humanReadableObjectNames,
  objectIcons,
} from 'embercom/models/data/matching-system/matching-constants';
import { capitalize } from '@ember/string';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Args {
  contentWrapper: ContentWrapper;
}

export default class ContentTypeCell extends Component<Args> {
  @service declare intl: IntlService;

  get icon(): InterfaceIconName {
    return objectIcons[this.args.contentWrapper.contents.firstObject!.contentType];
  }

  get contentType(): string {
    let humanReadableObjectName =
      humanReadableObjectNames[this.args.contentWrapper.contents.firstObject!.contentType];
    return capitalize(humanReadableObjectName);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::List::Cells::ContentTypeCell': typeof ContentTypeCell;
  }
}
