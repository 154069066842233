/* import __COLOCATED_TEMPLATE__ from './review-suggestion.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type ContentWrapper from 'embercom/models/content-service/content-wrapper';
import { put } from 'embercom/lib/ajax';
import { tracked } from '@glimmer/tracking';
import { restartableTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import type IntlService from 'ember-intl/services/intl';
import type AiContentLibraryApi from 'embercom/lib/ai-content-library/list-api';
import { CAN_MANAGE_KNOWLEDGE_BASE_CONTENT } from 'embercom/lib/knowledge-hub/constants';

interface Args {
  selectedWrappers: Array<
    ContentWrapper & { relations: any & { content_segment_ids: Array<number> } }
  >;
  unselectedWrappers: Array<
    ContentWrapper & { relations: any & { content_segment_ids: Array<number> } }
  >;
  isBulkSelection: boolean;
  listApi: AiContentLibraryApi;
  isDisabled: boolean;
  reloadPageAndSummary: () => void;
}

export default class ReviewSuggestion extends Component<Args> {
  @service declare appService: any;
  @service declare intercomEventService: any;
  @service declare intl: IntlService;
  @service declare permissionsService: $TSFixMe;
  @service declare notificationsService: any;
  @tracked selectedReviewValue = '';

  get contentToReview() {
    return this.args.selectedWrappers.map((wrapper) => {
      let object = wrapper.contents.firstObject!;
      return {
        entity_id: object.contentId,
        entity_type: object.contentType,
      };
    });
  }

  get unselectedTuples() {
    return this.args.unselectedWrappers.map((wrapper) => {
      let object = wrapper.contents.firstObject!;
      return {
        entity_id: object.contentId,
        entity_type: object.contentType,
      };
    });
  }

  get isLoading() {
    return taskFor(this.reviewSuggestionValue).isRunning;
  }

  get notificationMessage() {
    return this.selectedReviewValue === 'bulk_approve_suggestion'
      ? this.intl.t(
          'ai-content-library.content-reviews.bulk-actions.review-suggestion.approval-message',
          {
            count: this.contentToReview.length,
          },
        )
      : this.intl.t(
          'ai-content-library.content-reviews.bulk-actions.review-suggestion.reject-message',
          {
            count: this.contentToReview.length,
          },
        );
  }

  @action async reviewSuggestion(selectedValue: string) {
    try {
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
    } catch (e) {
      return;
    }

    this.selectedReviewValue = selectedValue;
    await taskFor(this.reviewSuggestionValue).perform();

    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: selectedValue,
      section: 'operator',
      context: 'review_suggestion_button',
      place: 'fin_content_suggestion',
    });
    this.notificationsService.notifyConfirmation(this.notificationMessage);
    this.args.reloadPageAndSummary();
  }

  @restartableTask private *reviewSuggestionValue() {
    yield put(`/ember/content_service/contents/${this.selectedReviewValue}`, {
      app_id: this.appService.app.id,
      contents_to_review: this.args.isBulkSelection ? null : this.contentToReview,
      admin_id: this.appService.app.currentAdmin.id,
      unselected_tuples: this.args.isBulkSelection ? this.unselectedTuples : null,
      query: this.args.isBulkSelection
        ? this.args.listApi.searchParams || this.args.listApi.defaultSearchParams
        : null,
      is_bulk_selection: this.args.isBulkSelection,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::BulkActions::ReviewSuggestion': typeof ReviewSuggestion;
  }
}
