/* import __COLOCATED_TEMPLATE__ from './column-selector.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { type ColumnData } from 'embercom/lib/ai-content-library/constants';
import { type TableColumn } from 'embercom/components/content-service/ai-content-library';

interface Args {
  onSelectionChange: (columns: string[]) => void;
  selectedColumns: TableColumn[];
  selectableColumns: ColumnData[];
}

export default class ColumnSelector extends Component<Args> {
  @service declare intl: IntlService;
  @service declare appService: any;

  @tracked selectedColumns: string[];
  @tracked selectableColumns: ColumnData[] = this.args.selectableColumns;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    // Only evaluate this once, not each time the outboundHomeService changes.
    this.selectedColumns = args.selectedColumns.map((col) => col.valuePath);
  }

  get columns() {
    return this.selectableColumns.map((column) => {
      let data: ColumnData = column;
      if (column.labelIntlKey) {
        data['text'] = this.intl.t(column.labelIntlKey);
      }
      data['isSelected'] = this.selectedColumns.includes(column.value);
      return data;
    });
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::ColumnSelector': typeof ColumnSelector;
  }
}
