/* import __COLOCATED_TEMPLATE__ from './content-adapter-component.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import { objectNames } from 'embercom/models/data/matching-system/matching-constants';
import { EntityType } from 'embercom/models/data/entity-types';
import { inject as service } from '@ember/service';

import ExternalContentAdapter from './adapters/external-content';
import type ContentWrapperComponent from './adapters/content-wrapper-component';
import ArticleContentAdapter from './adapters/article-content';
import ContentSnippetAdapter from './adapters/content-snippet';
import FileSourceContentAdapter from './adapters/file-source-content';

const CONTENT_EDITORS = {
  [EntityType.ExternalContent]: ExternalContentAdapter,
  [EntityType.ArticleContent]: ArticleContentAdapter,
  [EntityType.ContentSnippet]: ContentSnippetAdapter,
  [EntityType.FileSourceContent]: FileSourceContentAdapter,
} as Partial<Record<EntityType, typeof ContentWrapperComponent>>;

const SUPPORTED_TYPES = new Set(Object.keys(CONTENT_EDITORS).map((k) => Number(k) as EntityType));
import type AiContentLibraryApi from 'embercom/lib/ai-content-library/list-api';

export interface Args {
  contentType: EntityType;
  contentId: number;
  listApi: AiContentLibraryApi;
  errorMessageKey: string;
  loadNextContent: (position: number) => void;
  loadContentForPosition: (position: number) => void;
  removeLastContent: () => void;
  onClose: () => void;
  reloadSummary: () => void;
  reloadPage: () => void;
}

export default class ContentAdapterComponent extends Component<Args> {
  @service declare appService: any;

  supportedTypes: Set<EntityType>;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.supportedTypes = new Set(SUPPORTED_TYPES);

    if (!this.supportedTypes.has(this.contentType)) {
      console.error(
        `AI Content Library does not support content type [${
          objectNames[this.contentType]
        }] EntityType: (${this.contentType})`,
      );
    }
  }

  get contentId(): number {
    return Number(this.args.contentId);
  }

  get contentType(): EntityType {
    return this.args.contentType;
  }

  get knownComponents() {
    return CONTENT_EDITORS;
  }

  get isSupported(): boolean {
    return this.supportedTypes.has(this.contentType);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::ContentAdapterComponent': typeof ContentAdapterComponent;
  }
}
