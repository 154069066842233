/* import __COLOCATED_TEMPLATE__ from './status-cell.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
/*  */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type ContentWrapper from 'embercom/models/content-service/content-wrapper';
import { Status } from 'embercom/models/data/content-service/content-review-statuses';

interface Args {
  contentWrapper: ContentWrapper;
}

export default class StatusCell extends Component<Args> {
  @service declare intl: IntlService;

  get text(): string {
    if (this.isPending) {
      return this.intl.t('ai-content-library.content-reviews.list.cells.review-status.pending');
    } else {
      return this.intl.t(
        'ai-content-library.content-reviews.list.cells.review-status.not-applicable',
      );
    }
  }

  get isPending(): boolean {
    return this.args.contentWrapper.contents.firstObject!.contentReviewStatus === Status.Pending;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::ContentReviews::StatusCell': typeof StatusCell;
  }
}
