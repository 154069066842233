/* import __COLOCATED_TEMPLATE__ from './file-source-content.hbs'; */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type FileSourceContent from 'embercom/models/file-source-content';
import Component from '@glimmer/component';
import { type TaskGenerator } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { type ContentWrapperArgs } from './content-wrapper-component';
import type ContentImportService from 'embercom/services/content-import-service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';
import type FileSource from 'embercom/models/content-service/file-source';
import type Admin from 'embercom/models/admin';
import { State } from 'embercom/models/data/external-contents/constants';
import { isLocaleSupported } from 'embercom/components/content-service/ai-content-library/helpers';
import { CAN_MANAGE_KNOWLEDGE_BASE_CONTENT } from 'embercom/lib/knowledge-hub/constants';

interface FileSourceContentAdapterArgs extends ContentWrapperArgs {
  onReupload: () => void;
}

export default class FileSourceContentAdapter extends Component<FileSourceContentAdapterArgs> {
  @service declare store: Store;
  @service declare appService: any;
  @service declare contentImportService: ContentImportService;
  @service declare intercomEventService: any;
  @service declare intl: IntlService;
  @service declare notificationsService: any;
  @service declare permissionsService: any;

  @tracked openSectionId = 'details';
  @tracked showFileUploadModal = false;

  fileSourceContent?: FileSourceContent;
  fileSource?: FileSource;

  constructor(owner: unknown, args: FileSourceContentAdapterArgs) {
    super(owner, args);
    taskFor(this.loadFileSourceContent).perform();
  }

  get id() {
    return this.args.contentId;
  }

  get isLoading() {
    return taskFor(this.loadFileSourceContent).isRunning;
  }

  get isUsedByFin(): boolean {
    return this.supportedLanguage && Boolean(this.fileSourceContent?.isUsedByFin);
  }

  get supportedLanguage() {
    if (!this.fileSourceContent) {
      return false;
    }
    return isLocaleSupported(this.fileSourceContent.languageCode);
  }

  @action async showFileReuploadModal(): Promise<void> {
    try {
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
      this.showFileUploadModal = true;
    } catch (e) {
      return;
    }
  }

  @action async deleteFileSourceContent(): Promise<void> {
    if (!this.fileSourceContent) {
      return;
    }
    let fileSource = await this.store.peekRecord(
      'content-service/file-source',
      this.fileSourceContent.sourceId,
    );
    try {
      await fileSource.destroyRecord();
      this.notificationsService.notifyConfirmation(
        this.intl.t('ai-content-library.file-content.side-drawer.delete-success'),
      );

      this.fileSourceContent.unloadRecord();
      this.args.reloadSummary();
      this.args.reloadPage();
      this.args.onClose();
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: this.intl.t('ai-content-library.file-content.side-drawer.delete-error'),
      });
    }
    this.trackAnalyticsEvent('clicked', 'delete_button');
  }

  @action onFileReuploadSuccess(): void {
    this.args.reloadPage();
    this.args.onClose();
    this.notificationsService.notifyConfirmation(
      this.intl.t('ai-content-library.file-content.side-drawer.reupload-success'),
    );
    this.contentImportService.showFinIngestionJobStatusBanner();
  }

  @action async toggleFinAvailability(): Promise<void> {
    if (!this.fileSourceContent) {
      return;
    }
    try {
      this.trackAnalyticsEvent(
        'toggled',
        this.fileSourceContent.state === State.published
          ? 'fin_state_disabled'
          : 'fin_state_enabled',
      );
      await this.fileSourceContent.toggleState();
      this.onUpdate();
    } catch (e) {
      this.notificationsService.notifyResponseError(e);
    }
  }

  @action onUpdate(): void {
    this.args.reloadSummary();
  }

  get updatedAt() {
    return String(this.fileSourceContent?.updatedAt);
  }

  get teammate(): Admin | undefined {
    return this.fileSource?.lastUpdatedByTeammate;
  }

  @task *loadFileSourceContent(): TaskGenerator<void> {
    try {
      let result = yield this.store.findRecord('file-source-content', this.id);

      this.fileSourceContent = result as FileSourceContent;
      this.fileSource = yield this.store.findRecord(
        'content-service/file-source',
        this.fileSourceContent.sourceId,
      );
    } catch (err) {
      this.notificationsService.notifyError(
        this.intl.t('ai-content-library.file-content.error-codes.not-found'),
      );
      this.args.onClose();
    }
  }

  @action onOpenSectionChange(sectionId: string) {
    this.openSectionId = sectionId;
  }

  @action async setLocale(newLocale: string): Promise<void> {
    try {
      await this.fileSourceContent?.updateLanguageCode(newLocale);
      this.onUpdate();
    } catch (e) {
      this.notificationsService.notifyResponseError(e);
    }
  }

  get toggleIsEnabled() {
    if (!this.fileSourceContent) {
      return false;
    }

    return isLocaleSupported(this.fileSourceContent.languageCode);
  }

  private trackAnalyticsEvent(action: string, object: string): void {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      section: 'operator',
      context: 'file_content_side_drawer',
      place: 'fin_content_page',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::Adapters::FileSourceContent': typeof FileSourceContent;
  }
}
