/* import __COLOCATED_TEMPLATE__ from './standard-header.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import PredicateGroup from 'predicates/models/predicate-group';
import {
  messengerDependentObjects as MESSENGER_BASED_OBJECTS,
  messengerWebAndMobileDependentObjects as MESSENGER_WEB_AND_MOBILE_BASED_OBJECTS,
  objectTypes as OBJECT_TYPES,
} from 'embercom/models/data/matching-system/matching-constants';
import { task } from 'ember-concurrency-decorators';

const HELP_ARTICLE_IDS = {
  sdk: 567786,
  whatsapp: 5454490,
};

const HELP_COLLECTION_LINKS = {
  messenger:
    'https://www.intercom.com/help/en/collections/2094823-installing-the-intercom-messenger',
  'messenger-web-and-mobile':
    'https://www.intercom.com/help/en/collections/2094823-installing-the-intercom-messenger',
};

export default class StandardHeader extends Component {
  @service contentEditorService;
  @service appService;
  @service store;
  @service router;
  @service intercomEventService;
  @service guideLibraryService;
  @service intl;

  customBot = this.contentEditorService.activeObject.isCustomBot
    ? this.contentEditorService.activeObject
    : undefined;

  constructor() {
    super(...arguments);
    if (this.triggerPredicates && this.customBot && !this.customBot.triggerPredicateGroup) {
      this.customBot.triggerPredicateGroup = this.store.createFragment(
        'predicates/predicate-group',
        {
          predicates: PredicateGroup.convertRawPredicates(this.store, [
            {
              type: this.triggerPredicates.type,
              predicates: [this.triggerPredicates.options.firstObject.predicate],
            },
          ]),
        },
      );
    }
  }

  get selectedTriggerPredicates() {
    return this.customBot.triggerPredicateGroup.predicates.firstObject?.predicates.map(
      (o) => o.value,
    );
  }

  get triggerPredicates() {
    if (!this.customBot?.config) {
      return;
    }

    return this.customBot.config.triggerPredicates;
  }

  isLogicalTriggerPredicate(type) {
    return ['or', 'and'].includes(type);
  }

  get requiredSetupKey() {
    let objectType = this.contentEditorService.activeRulesetLink.objectType;
    if (MESSENGER_WEB_AND_MOBILE_BASED_OBJECTS.includes(objectType)) {
      return 'messenger-web-and-mobile';
    } else if (MESSENGER_BASED_OBJECTS.includes(objectType)) {
      return 'messenger';
    } else if (objectType === OBJECT_TYPES.email) {
      return 'email';
    } else if (objectType === OBJECT_TYPES.sms) {
      return 'sms';
    } else if ([OBJECT_TYPES.push, OBJECT_TYPES.carousel].includes(objectType)) {
      return 'sdk';
    } else if (objectType === OBJECT_TYPES.whatsapp) {
      return 'whatsapp';
    }

    return 'messenger';
  }

  get paywallOverrideForSmsBeta() {
    return !this.appService.app.canUseSmsBeta && !this.appService.app?.canUseFeature('sms');
  }

  get shouldShowEmailDeliverabilityBanner() {
    let setupKey = this.requiredSetupKey;
    return setupKey === 'email';
  }

  // disable setting SMS messages live when the workspace doesn't have an activated country
  // uses the implicit predicates to check whether there are any activated countries or not
  // The banner should only be displayed when the workspace isn't paywalled and doesn't have any activated countries
  get showSmsActivateCountryBanner() {
    let setupKey = this.requiredSetupKey;
    if (setupKey !== 'sms') {
      return false;
    }
    return (
      !this.contentEditorService.hasActivatedCountriesForSms() && !this.paywallOverrideForSmsBeta
    );
  }

  get shouldShowSetupWarningBanner() {
    let setupKey = this.requiredSetupKey;
    if (['messenger', 'messenger-web-and-mobile'].includes(setupKey)) {
      return !this.guideLibraryService.isMessengerInstalled;
    } else if (setupKey === 'email') {
      return false;
    } else if (setupKey === 'whatsapp') {
      return !this.appService.app.hasActiveWhatsAppIntegration;
    } else if (setupKey === 'sms') {
      return false;
    } else if (setupKey === 'sdk') {
      return !(
        this.appService.app?.iosSdkApiKeyIsActivated ||
        this.appService.app?.androidSdkApiKeyIsActivated
      );
    }
    return false;
  }

  get helpLinkArticleId() {
    return HELP_ARTICLE_IDS[this.requiredSetupKey];
  }

  get helpCollectionLink() {
    return HELP_COLLECTION_LINKS[this.requiredSetupKey];
  }

  get bannerButtonLabel() {
    return this.intl.t(
      `content-editor.headers.edit-mode.standard-header.banner.${this.requiredSetupKey}-button`,
    );
  }

  get bannerDescriptionLabel() {
    return this.intl.t(
      `content-editor.headers.edit-mode.standard-header.banner.${this.requiredSetupKey}`,
      { htmlSafe: true },
    );
  }

  get bannerHelpLinkLabel() {
    return this.intl.t(
      `content-editor.headers.edit-mode.standard-header.banner.${this.requiredSetupKey}-help-link`,
    );
  }

  @action
  transitionToInstallationPage() {
    let setupKey = this.requiredSetupKey;
    let route = 'apps.app.settings.channels.messenger.install';
    let queryParams = {};

    if (setupKey === 'sms') {
      route = 'apps.app.settings.channels.sms';
    } else if (setupKey === 'sdk') {
      route = 'apps.app.settings.channels.messenger.install';
      queryParams = { tab: 'ios' };
    } else if (setupKey === 'messenger-web-and-mobile') {
      route = 'apps.app.settings.channels.messenger.install';
    } else if (setupKey === 'whatsapp') {
      route = 'apps.app.settings.channels.whatsapp';
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: `proactive_warning_banner_with_${setupKey}_cta`,
      place: 'proactive_support_content_editor',
      context: `proactive_support_content_editor.navigating_to_${route}`,
      section: 'content_editor.install_msgr',
    });
    this.router.transitionTo(route, { queryParams });
  }

  @action
  async save() {
    if (
      this.args.config.preActivationCheck &&
      (this.contentEditorService.ruleset.isLive || this.contentEditorService.ruleset.isScheduled)
    ) {
      this.args.config.preActivationCheck(this.saveRuleset);
      return;
    }
    this.saveRuleset.perform();
  }

  @task
  *saveRuleset() {
    let result = yield this.contentEditorService.saveRuleset.perform();
    if (result?.success) {
      this.contentEditorService.changeToViewMode();
      if (this.args.postActivationCheck && this.contentEditorService.ruleset.isLive) {
        this.args.postActivationCheck();
      }
      if (this.args.config.afterSaveRedirectRoute) {
        this.router.transitionTo(this.args.config.afterSaveRedirectRoute);
      }
    }
  }

  get email() {
    return this.contentEditorService.activeObject;
  }

  get localizedEmailContent() {
    return this.email.localizedEmailContents.firstObject;
  }

  @action
  async cancel() {
    await this.contentEditorService.cancelRulesetChanges.perform();
    this.contentEditorService.changeToViewMode();
    this.contentEditorService.uploadFileFinished();
  }

  @action
  trimContentTitle() {
    this.contentEditorService.ruleset.clientData.title =
      this.contentEditorService.ruleset.clientData.title.trim();
  }

  @action
  toggleTriggerPredicateOption(value) {
    // at least one predicate must be selected
    if (
      this.selectedTriggerPredicates.includes(value) &&
      this.selectedTriggerPredicates.length === 1
    ) {
      return;
    }

    let triggerPredicates = this.triggerPredicates;
    if (!triggerPredicates) {
      return;
    }

    if (this.isLogicalTriggerPredicate(triggerPredicates.type)) {
      let predicates = [];
      [
        ...this.selectedTriggerPredicates.filter((v) => v !== value),
        ...(!this.selectedTriggerPredicates.includes(value) ? [value] : []),
      ].forEach((key) => {
        triggerPredicates.options.find((option) => {
          if (option.value === key) {
            predicates.push(option.predicate);
          }
        });
      });

      if (!predicates.length) {
        return;
      }

      this.customBot.triggerPredicateGroup = this.store.createFragment(
        'predicates/predicate-group',
        {
          predicates: PredicateGroup.convertRawPredicates(this.store, [
            { type: triggerPredicates.type, predicates },
          ]),
        },
      );
    }
  }
}
