/* import __COLOCATED_TEMPLATE__ from './side-drawer.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { CAN_MANAGE_KNOWLEDGE_BASE_CONTENT } from 'embercom/lib/knowledge-hub/constants';
import type ContentImportSource from 'embercom/models/content-service/content-import-source';

interface Args {
  onClose: () => void;
  isOpen: boolean;
  sources: Array<ContentImportSource>;
  resetSearch: () => void | Promise<void>;
}

export default class SideDrawer extends Component<Args> {
  @tracked showAddSourceModal = false;
  @service declare permissionsService: any;
  @service declare appService: any;

  @action async showAddSourceModalAction(): Promise<void> {
    try {
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
      this.showAddSourceModal = true;
    } catch (e) {
      return;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::Sources::SideDrawer': typeof SideDrawer;
  }
}
