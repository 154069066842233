/* import __COLOCATED_TEMPLATE__ from './fin-content-modal.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { type TemplateCardType } from 'embercom/lib/ai-content-library/constants';
import { EntityType } from 'embercom/models/data/entity-types';

interface Args {
  onClose: () => void;
  onCreateText: () => void;
  onCreatePublicUrl: () => void;
  onCreateFile: () => void;
  onCreateArticle?: () => void;
  onCreateCustomAnswer?: () => void;
  templates: Array<TemplateCardType>;
}

export default class FinContentModal extends Component<Args> {
  @service declare appService: any;

  get app() {
    return this.appService.app;
  }

  @action
  onClick(entityType: EntityType) {
    switch (entityType) {
      case EntityType.ContentSnippet:
        this.args.onCreateText();
        break;
      case EntityType.ExternalContent:
        this.args.onCreatePublicUrl();
        break;
      case EntityType.FileSourceContent:
        this.args.onCreateFile();
        break;
      case EntityType.ArticleContent:
        if (this.args.onCreateArticle) {
          this.args.onCreateArticle();
        }
        break;
      case EntityType.Answer:
        if (this.args.onCreateCustomAnswer) {
          this.args.onCreateCustomAnswer();
        }
        break;
      default:
        this.args.onClose();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::FinContentModal': typeof FinContentModal;
  }
}
