/* import __COLOCATED_TEMPLATE__ from './segment-settings.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import Component from '@glimmer/component';

import type Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import type AiContentSegmentsService from 'embercom/services/ai-content-segments-service';
import { action } from '@ember/object';
import type AiContentSegment from 'embercom/models/ai-content-segment';
import { helper } from '@ember/component/helper';
import type ContentImportSource from 'embercom/models/content-service/content-import-source';
import { post } from 'embercom/lib/ajax';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'embercom/services/intl';
import type HelpCenterSite from 'embercom/models/help-center-site';
import { CAN_MANAGE_KNOWLEDGE_BASE_CONTENT } from 'embercom/lib/knowledge-hub/constants';

interface Args {
  source: ContentImportSource | HelpCenterSite;
  reloadPage: () => void;
}

enum SaveType {
  ALL = 'all',
  FUTURE = 'future',
}

enum SegmentOperation {
  ADD = 'add',
  REMOVE = 'remove',
  REMOVE_ALL = 'remove_all',
}

export default class SegmentSettings extends Component<Args> {
  @service declare store: Store;
  @service declare appService: any;
  @service declare aiContentSegmentsService: AiContentSegmentsService;
  @service declare permissionsService: $TSFixMe;
  @tracked showSaveSourceAudienceModal = false;
  @tracked modalSelectedSaveType: string = SaveType.ALL;
  @tracked segmentOperation: string = SegmentOperation.ADD;

  @service declare intl: IntlService;

  updateAssociatedContent = false;
  currentSegment?: number;
  segmentIsAppliedToSelection = helper(function ([segment, contentSegmentIds]: [
    AiContentSegment,
    Array<number>,
  ]): boolean {
    return contentSegmentIds?.includes(Number(segment.id));
  });

  noSegmentsAppliedToSelection = helper(function ([contentSegmentIds]: [Array<number>]): boolean {
    return !contentSegmentIds?.length;
  });

  get saveTypeAll(): string {
    return SaveType.ALL;
  }

  get saveTypeFuture(): string {
    return SaveType.FUTURE;
  }

  @action removeAllSegments() {
    this.segmentOperation = SegmentOperation.REMOVE_ALL;
    this.openAssociatedContentModal();
  }

  @action async confirmRemoveAllSegments() {
    this.args.source.contentSegmentIds = [];
    this.args.source.notifyPropertyChange('contentSegmentIds');
    await this.addOrRemoveSegments(
      [],
      [],
      true,
      this.args.source,
      this.modalSelectedSaveType === SaveType.ALL,
    );
    this.clearModal();
  }

  @action openAssociatedContentModal() {
    this.showSaveSourceAudienceModal = true;
  }

  @action async applySegment(segment: AiContentSegment) {
    this.currentSegment = Number(segment.id);
    let segmentIds = this.args.source.contentSegmentIds;

    if (segmentIds.includes(this.currentSegment) && segmentIds.length === 1) {
      this.segmentOperation = SegmentOperation.REMOVE_ALL;
    } else if (segmentIds.includes(this.currentSegment)) {
      this.segmentOperation = SegmentOperation.REMOVE;
    } else {
      this.segmentOperation = SegmentOperation.ADD;
    }
    this.openAssociatedContentModal();
  }

  @action async addSegmentToSource() {
    try {
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
    } catch (e) {
      return;
    }
    if (this.currentSegment) {
      this.args.source.contentSegmentIds.push(this.currentSegment);
      this.args.source.notifyPropertyChange('contentSegmentIds');
      await this.addOrRemoveSegments(
        [this.currentSegment],
        [],
        false,
        this.args.source,
        this.modalSelectedSaveType === SaveType.ALL,
      );
    }
    this.clearModal();
  }

  @action clearModal() {
    this.showSaveSourceAudienceModal = false;
    this.modalSelectedSaveType = SaveType.ALL;
  }

  @action async removeSegmentFromSource() {
    try {
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
    } catch (e) {
      return;
    }
    if (this.currentSegment) {
      this.args.source.contentSegmentIds.splice(
        this.args.source.contentSegmentIds.indexOf(this.currentSegment),
        1,
      );
      this.args.source.notifyPropertyChange('contentSegmentIds');
      await this.addOrRemoveSegments(
        [],
        [this.currentSegment],
        false,
        this.args.source,
        this.modalSelectedSaveType === SaveType.ALL,
      );
    }
    this.clearModal();
  }

  @action
  showModal() {
    this.showSaveSourceAudienceModal = true;
  }

  get segments() {
    return this.aiContentSegmentsService.segments;
  }

  get audienceLabel() {
    if (this.args.source.contentSegmentIds.length === 0) {
      return this.intl.t('ai-content-library.segments.dropdown.all-segments');
    }

    let firstSegmentName = this.store.peekRecord(
      'ai-content-segment',
      this.args.source.contentSegmentIds[0],
    ).nameWithFallback;

    if (this.args.source.contentSegmentIds.length === 1) {
      return firstSegmentName;
    }

    return this.intl.t('ai-content-library.segments.dropdown.additional-audience-segments', {
      segmentName: firstSegmentName,
      count: this.args.source.contentSegmentIds.length - 1,
    });
  }

  @action async addOrRemoveSegments(
    segmentIdsToAdd: Array<number>,
    segmentIdsToDelete: Array<number>,
    isAudienceReset = false,
    source: ContentImportSource | HelpCenterSite,
    updateAssociatedContent = false,
  ) {
    let response = await post('/ember/ai_content_segments/update_memberships', {
      app_id: this.appService.app.id,
      segment_ids_to_add: segmentIdsToAdd,
      segment_ids_to_delete: segmentIdsToDelete,
      content_tuples: [{ entity_id: Number(source.id), entity_type: source.entityType }],
      unselected_tuples: null,
      query: null,
      is_bulk_selection: false,
      is_audience_reset: isAudienceReset,
      update_associated_content: updateAssociatedContent,
    });
    this.args.reloadPage();
    return response;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::Segments::Contents::SegmentSettings': typeof SegmentSettings;
  }
}
