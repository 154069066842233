/* import __COLOCATED_TEMPLATE__ from './empty-state.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { type TemplateCardType } from 'embercom/lib/ai-content-library/constants';
import { EntityType } from 'embercom/models/data/entity-types';

interface Args {
  onCreateText: () => void;
  onCreatePublicUrl: () => void;
  onCreateFile: () => void;
  mode?: string;
  hasAppliedFilter: boolean;
  templates: Array<TemplateCardType>;
}

export default class EmptyState extends Component<Args> {
  @service declare appService: any;

  get app() {
    return this.appService.app;
  }

  @action
  onClick(entityType: EntityType) {
    switch (entityType) {
      case EntityType.ContentSnippet:
        this.args.onCreateText();
        break;
      case EntityType.ExternalContent:
        this.args.onCreatePublicUrl();
        break;
      case EntityType.FileSourceContent:
        this.args.onCreateFile();
        break;
      default:
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::EmptyState': typeof EmptyState;
  }
}
