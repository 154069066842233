/* import __COLOCATED_TEMPLATE__ from './template-card.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import { type TemplateCardType } from 'embercom/lib/ai-content-library/constants';
import { type EntityType } from 'embercom/models/data/entity-types';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';

interface Args {
  onClick: (entityType: EntityType) => void;
  emptyState: boolean | false;
  templates: Array<TemplateCardType>;
}

export default class TemplateCard extends Component<Args> {
  @service declare appService: any;
  @service intl!: IntlService;

  get templates() {
    let details: any = [];
    this.args.templates.forEach((type) => {
      details.pushObject({
        id: type.id,
        title: this.templateTitle(type.title),
        summary: this.intl.t(
          `ai-content-library.content-snippet.new-content-modal.${type.summary}`,
        ),
        icon: type.icon,
        color: type.color,
        action: this.args.onClick,
      });
    });

    return details;
  }

  templateTitle(type: string) {
    if (this.args.emptyState) {
      return this.intl.t(`ai-content-library.empty-state.${type}`);
    }
    return this.intl.t(`ai-content-library.content-snippet.new-content-modal.${type}`);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::TemplateCard': typeof TemplateCard;
  }
}
