/* import __COLOCATED_TEMPLATE__ from './external-content.hbs'; */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type ExternalContent from 'embercom/models/external-content';

import { type TaskGenerator } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import ContentWrapperComponent, { type ContentWrapperArgs } from './content-wrapper-component';
import { action } from '@ember/object';
import type ContentImportService from 'embercom/services/content-import-service';
import type IntlService from 'ember-intl/services/intl';

export default class ExternalContentAdapter extends ContentWrapperComponent {
  @service declare store: Store;
  @service declare contentImportService: ContentImportService;
  @service declare notificationsService: any;
  @service declare intl: IntlService;

  externalContent?: ExternalContent;

  constructor(owner: unknown, args: ContentWrapperArgs) {
    super(owner, args);
    taskFor(this.loadExternalContent).perform();
  }

  @action onUpdate(): void {
    this.args.reloadSummary();
  }

  get id() {
    return this.args.contentId;
  }

  get isLoading() {
    return taskFor(this.loadExternalContent).isRunning;
  }

  @task *loadExternalContent(): TaskGenerator<void> {
    try {
      let result = yield Promise.all([
        this.store.findRecord('external-content', this.id),
        this.contentImportService.fetchContentImportSources(),
      ]);

      this.externalContent = result.firstObject as ExternalContent;
    } catch (err) {
      this.notificationsService.notifyError(
        this.intl.t('ai-content-library.external-content.not-found'),
      );
      this.args.onClose();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::Adapters::ExternalContent': typeof ExternalContentAdapter;
  }
}
