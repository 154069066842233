/* import __COLOCATED_TEMPLATE__ from './content-bytesize-cell.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import type ContentWrapper from 'embercom/models/content-service/content-wrapper';
import type IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import { MAX_LIBRARY_SIZE } from 'embercom/lib/content-service/constants';

interface Args {
  contentWrapper: ContentWrapper;
}

export default class ContentBytesizeCell extends Component<Args> {
  @service declare intl: IntlService;

  get contentBytesize(): number {
    return this.args.contentWrapper.contents.firstObject!.contentBytesize;
  }

  get contentSizeInKilobytes(): string {
    return (this.contentBytesize / 1000).toFixed(2);
  }

  get contentBytesizeAsPercentage(): string {
    return ((this.contentBytesize / MAX_LIBRARY_SIZE) * 100).toFixed(2);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::List::Cells::ContentBytesizeCell': typeof ContentBytesizeCell;
  }
}
