/* import __COLOCATED_TEMPLATE__ from './content-snippet-editor.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { BaseConfig, BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import type ContentSnippet from 'embercom/models/content-service/content-snippet';
import { type Block } from '@intercom/interblocks.ts';

export class ContentSnippetComposerConfig extends BaseConfig {
  autoFocus = false;
  allowedBlocks = ['paragraph', 'heading', 'subheading', 'small', 'orderedList', 'unorderedList'];
  allowTextAlignment = false;
  allowedInline = ['bold', 'italic', 'anchor'];
  experimental = {
    hideInsertersOnMouseOut: true,
  };
  textDirection = 'ltr' as const;
  tools = [{ name: 'text-formatter' }, { name: 'anchor-editor' }];
  analytics: $TSFixMe;
  eventService: $TSFixMe;
  intl: $TSFixMe;

  constructor({
    eventService,
    intl,
    placeholder,
  }: {
    eventService: $TSFixMe;
    intl: $TSFixMe;
    placeholder: $TSFixMe;
  }) {
    super();
    this.analytics = {
      trackAnalyticsEvent: (eventName: string, attrs: Record<string, unknown>) => {
        eventService.trackAnalyticsEvent({
          action: eventName,
          object: 'text-snippet',
          place: 'composer',
          ...attrs,
        });
      },
    };

    (this.eventService = eventService), (this.intl = intl), (this.placeholder = placeholder);
  }
}

interface Args {
  placeholder?: string;
  contentSnippet: ContentSnippet;
  styleForKnowledge?: boolean;
  className?: string;
  onUpdateBlocks?: (blocksDoc: BlocksDocument) => void;
  blocksDocument?: BlocksDocument;
}

export default class ContentSnippetEditor extends Component<Args> {
  @service appService: $TSFixMe;
  @service intl: $TSFixMe;
  @service store: $TSFixMe;
  @service intercomEventService: $TSFixMe;
  blocksDoc: BlocksDocument;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.blocksDoc = args.blocksDocument || new BlocksDocument(this.serializedContentSnippetBlocks);
  }

  get app() {
    return this.appService.app;
  }

  get textSnippetComposerConfig() {
    return new ContentSnippetComposerConfig({
      eventService: this.intercomEventService,
      intl: this.intl,
      placeholder:
        this.args.placeholder ||
        this.intl.t('ai-content-library.content-snippet.editor.placeholder'),
    });
  }

  get serializedContentSnippetBlocks() {
    return this.args.contentSnippet?.latestVersion?.jsonBlocks.serialize();
  }

  @action updateBlocks(blocksDoc: BlocksDocument) {
    this.blocksDoc = blocksDoc;
    let blockFragments = blocksDoc.blocks.map((block: Block) =>
      createFragmentFromBlock(this.store, block),
    );
    if (this.args.onUpdateBlocks) {
      this.args.onUpdateBlocks(blocksDoc);
    } else {
      // @ts-ignore
      this.args.contentSnippet.newOrCurrentVersion.jsonBlocks = blockFragments;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::Composer::ContentSnippetEditor': typeof ContentSnippetEditor;
  }
}
