/* import __COLOCATED_TEMPLATE__ from './set-live-confirmation-body.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import {
  objectTypes,
  humanReadableObjectNamesKeys,
  objectNames,
} from 'embercom/models/data/matching-system/matching-constants';
import type IntlService from 'embercom/services/intl';
import { capitalize } from '@ember/string';
import { action } from '@ember/object';
import type Store from '@ember-data/store';
import { adminHasSeen, markViewAsSeen } from 'embercom/lib/admin-view-tracking';
import { use } from 'ember-resources/util/function-resource';
import { AsyncData } from 'embercom/resources/utils/async-data';

interface Args {
  confirmContext: {
    body: string;
    objectType: number;
    isDynamicMessage: boolean;
  };
}

const MeteredObjectsTypes = new Set([
  objectTypes.email,
  objectTypes.sms,
  objectTypes.whatsapp,
  objectTypes.post,
  objectTypes.push,
  objectTypes.tour,
  objectTypes.survey,
  objectTypes.carousel,
]);

const StandardChannelTypes = new Set([
  objectTypes.email,
  objectTypes.sms,
  objectTypes.whatsapp,
  objectTypes.push,
]);

export const SET_LIVE_CONFIRMATION_MODAL_PAGEVIEW_KEY = 'set-live-confirmation-modal';

export default class SetLiveConfirmationComponent extends Component<Args> {
  @service declare appService: any;
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare intercomEventService: any;
  @service declare cardlessTrialService: any;

  @use loadAdminPageViewData = AsyncData(async () => {
    return await adminHasSeen(this.store, this.pageviewKey);
  });

  get pageviewKey() {
    return `${SET_LIVE_CONFIRMATION_MODAL_PAGEVIEW_KEY}_${this.channelName}`;
  }

  get channelName() {
    return objectNames[this.args.confirmContext.objectType];
  }

  get hasAdminDismissedBillingNotice() {
    return this.loadAdminPageViewData.value ?? true;
  }

  @action
  actionViewPricing() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'pricing_5_metered_messages_pricing_link',
      channel_name: this.channelName,
      pricing_model: this.getPricingModelName,
    });

    window.Intercom('showArticle', this.appService.app.meteredMessagesArticleId);
  }

  @action
  dismissBillingNotice() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'dismiss_billing_notice',
      channel_name: this.channelName,
      pricing_model: this.getPricingModelName,
    });

    markViewAsSeen(this.store, this.pageviewKey);
  }

  get getPricingModelName() {
    if (this.appService.app.onPricing5) {
      return 'pricing5';
    }
    if (this.appService.app.hasValueBasedPricing2) {
      return 'vbp2';
    }
    if (this.appService.app.hasValueBasedPricing1) {
      return 'vbp1';
    }

    return 'other';
  }

  get body() {
    return this.args.confirmContext.body;
  }

  get humanReadableObjectNameTranslations() {
    return this.intl.t(humanReadableObjectNamesKeys[this.args.confirmContext.objectType], {
      count: 1,
    });
  }

  get showBillingNotice(): boolean {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'shown',
      object: 'confirmation_message',
      channel_name: this.channelName,
      pricing_model: this.getPricingModelName,
    });

    let canShowBillingNotice =
      this.appService.app.onPricing5 &&
      MeteredObjectsTypes.has(this.args.confirmContext.objectType) &&
      !this.cardlessTrialService.inTrialOrExpiredTrial &&
      !this.appService.app.isTestApp;

    return canShowBillingNotice;
  }

  get titleVariant() {
    // If we can see the title, that means that we are seeing the speedbump, so let's save this event here
    this.intercomEventService.trackAnalyticsEvent({
      action: 'shown',
      object: 'confirmation_message_speedbump',
      channel_name: this.channelName,
      pricing_model: this.getPricingModelName,
    });

    let translationKey = `content-editor.title-${this.channelName}`;

    return this.intl.t(translationKey, {
      channelType: this.humanReadableObjectNameTranslations,
    });
  }

  get isStandardChannelType() {
    return StandardChannelTypes.has(this.args.confirmContext.objectType);
  }

  get messageVariant() {
    let translationKey = this.appService.app.isSalesforceContracted
      ? 'content-editor.channel-sending-cost-message.contracted'
      : 'content-editor.channel-sending-cost-message.non-contracted';

    translationKey += this.args.confirmContext.isDynamicMessage
      ? '-dynamic-message'
      : '-non-dynamic-message';

    translationKey += this.isStandardChannelType ? '-standard' : '-proactive-plus';

    // For the mobile push, we are using one key in each scenario, this is the only exception
    if (this.args.confirmContext.objectType === objectTypes.push) {
      translationKey =
        'content-editor.channel-sending-cost-message.contracted-non-dynamic-message-standard';
    }

    return capitalize(
      this.intl.t(translationKey, {
        channelType: this.humanReadableObjectNameTranslations,
      }),
    );
  }

  get pricingLinkText() {
    let translationKey = `content-editor.channel-sending-cost-message.view-channel-type-link-text${
      this.isStandardChannelType ? '-standard' : '-proactive-plus'
    }`;

    if (this.args.confirmContext.objectType === objectTypes.push) {
      translationKey =
        'content-editor.channel-sending-cost-message.view-channel-type-link-text-proactive-plus';
    }

    if (
      this.args.confirmContext.objectType === objectTypes.whatsapp ||
      this.args.confirmContext.objectType === objectTypes.sms
    ) {
      translationKey = `content-editor.channel-sending-cost-message.view-channel-type-link-text-${this.channelName}`;
    }

    return this.intl.t(translationKey, {
      channelType: this.humanReadableObjectNameTranslations,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentEditor::SetLiveConfirmationBody': typeof SetLiveConfirmationComponent;
  }
}
